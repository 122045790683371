/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




/* ----------- Non-Retina Screens ----------- */
@media screen 
  and (min-device-width: 900px) 
  and (max-device-width: 900px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 900px) 
  and (max-device-width: 900px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
} 


  // BACKGROUNDS	
  $bk-red: #CC0000;	
  $bk-black: #000;	
  $bk-green: #00C337;	
  $text-colour-white: #fff;	
  $text-colour-green: #00C337;	
  $text-colour-red: #CC0000;	



  .bk-red{
      opacity: 0.85;
      background-color: $bk-red;
  }

  .bk-black{
    background-color: $bk-black;
}

.bk-green{
    opacity: 0.85;
    background-color: $bk-green;
}

.text-colour-white{
    color: $text-colour-white;
}

.text-colour-green{
    color: $text-colour-green;
}


.text-colour-red{
    color: $text-colour-red;
}




// FLEXBOX
.flexbox-center {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
.flexbox-wrap {
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}
.flexbox-wrap-child {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 45%;
}
.flexbox-space-between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
// POSITIONING
.center {
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
}
.mrg-0 {
	margin: 0;
}
.align-left {
	text-align: left;
}
.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}





.font-12 {
	font-size: 12px;
}
.font-13 {
	font-size: 13px;
}

.font-14 {
	font-size: 14px;
}
.font-17 {
	font-size: 17px;
}
.font-18 {
	font-size: 18px;
}
.font-22 {
	font-size: 22px;
}
.font-24 {
	font-size: 24px;
}

.font-27 {
	font-size: 27px;
}
.font-30 {
	font-size: 30px;
}
.font-36 {
	font-size: 36px;
}
.font-40 {
	font-size: 40px;
}
.lh-0 {
	line-height: 0;
}

p{
	letter-spacing: 0.02em;
}

textarea:focus, input:focus{
    outline: none;
}





input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}




.my-custom-modal-css .modal-wrapper {
	height: 100%;
	width: 100%;
	position: absolute; 
	display: block;  
 }
